import { Github, Linkedin } from "lucide-react";

function App() {
  return (
    <div className="w-screen bg-black text-white pt-10 px-8">
      <header className="mb-20">
        <h1 className="text-4xl font-bold tracking-tight text-slate-200">Leomar Linhares</h1>
        <h2 className="mt-3 text-lg font-medium tracking-tight text-slate-200">Desenvolvedor de Software</h2>
        <p className="mt-4 max-w-xs leading-normal">Sites e aplicações com React e .NET</p>
        <ul className="ml-1 mt-8 flex items-center">
          <li className="mr-5 text-xs shrink-0">
            <a className="block hover:text-slate-200 cursor-pointer" href="https://github.com/LeomarLinhares"><Github /></a>
          </li>
          <li className="mr-5 text-xs shrink-0">
            <a className="block hover:text-slate-200 cursor-pointer" href="https://www.linkedin.com/in/leomarlinhares"><Linkedin /></a>
          </li>
        </ul>
      </header>
      <main>
        <section id="about">
          <h2 className="my-8 text-sm font-bold uppercase tracking-widest text-slate-200">Sobre</h2>
          <p className="mb-4">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Error ad in placeat distinctio adipisci similique voluptatum consectetur nihil earum et. Nihil tempora atque ducimus possimus numquam dolores dignissimos repudiandae doloribus?</p>
          <p className="mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis voluptate earum eius quis qui aut provident officia ullam, quibusdam obcaecati molestiae accusamus itaque, possimus omnis nemo soluta ipsam consectetur maxime.</p>
        </section>

        <section id="experience">
          <h2 className="my-8 text-sm font-bold uppercase tracking-widest text-slate-200">Experiência</h2>
          <div className="py-4">
            <header className="uppercase text-sm font-medium text-gray-400 leading-8">Maio de 2022 - Presente</header>
            <div>
              <h3 className="leading-8 font-bold text-lg">Desenvolvedor Fullstack Junior • Win.win</h3>
              <p className="mt-2 text-sm leading-normal">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perferendis iure, numquam tenetur veniam impedit, nulla deleniti beatae, a laudantium soluta velit. Quo autem facilis nihil repellat, necessitatibus totam nulla labore!</p>
              <ul className="flex my-3">
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">React</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">.NET</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">Nginx</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">Azure</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="projects">
          <h2 className="my-8 text-sm font-bold uppercase tracking-widest text-slate-200">Projetos</h2>
          <div className="py-4">
            <div>
              <h3 className="leading-8 font-bold text-lg">Projeto topper</h3>
              <img className="mx-auto rounded my-4" alt="Teste" src="https://picsum.photos/300/200" />
              <p className="mt-2 text-sm leading-normal">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perferendis iure, numquam tenetur veniam impedit, nulla deleniti beatae, a laudantium soluta velit. Quo autem facilis nihil repellat, necessitatibus totam nulla labore!</p>
              <ul className="flex my-3">
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">React</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">.NET</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">Nginx</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">Azure</li>
              </ul>
            </div>
          </div>
          <div className="py-4">
            <div>
              <h3 className="leading-8 font-bold text-lg">Projeto topper</h3>
              <img className="mx-auto rounded my-4" alt="Teste" src="https://picsum.photos/300/200" />
              <p className="mt-2 text-sm leading-normal">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perferendis iure, numquam tenetur veniam impedit, nulla deleniti beatae, a laudantium soluta velit. Quo autem facilis nihil repellat, necessitatibus totam nulla labore!</p>
              <ul className="flex my-3">
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">React</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">.NET</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">Nginx</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">Azure</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="articles">
          <h2 className="my-8 text-sm font-bold uppercase tracking-widest text-slate-200">Experiência</h2>
          <div className="py-4">
            <header className="uppercase text-sm font-medium text-gray-400 leading-8">Maio de 2022 - Presente</header>
            <div>
              <h3 className="leading-8 font-bold text-lg">Desenvolvedor Fullstack Junior • Win.win</h3>
              <p className="mt-2 text-sm leading-normal">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Perferendis iure, numquam tenetur veniam impedit, nulla deleniti beatae, a laudantium soluta velit. Quo autem facilis nihil repellat, necessitatibus totam nulla labore!</p>
              <ul className="flex my-3">
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">React</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">.NET</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">Nginx</li>
                <li className="py-1.5 px-3 rounded-full bg-blue-400/10 text-blue-300 mr-1 text-xs font-bold">Azure</li>
              </ul>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
